import Banner from "@/components/Cliente/Banner/Banner.vue";

export default {
    name: "cliente-conteudo",
    components: {
        Banner
    },
    data() {
        return {
            blLoading: true,
            obConteudo: null
        };
    },
    created() {
        this.obter();
    },
    watch: {
        $route(to, from) {
            this.obter();
        }
    },
    methods: {
        obter() {
            this.blLoading = true;

            this.$root.$api
                .get("conteudo/obter/" + this.$route.params.id)
                .then(response => {
                    this.obConteudo = response.retorno;
                    this.blLoading = false;
                });
        }
    }
};
